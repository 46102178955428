import {
  baseResource,
  createResource,
  optionsResource,
  putResource,
  postResource,
} from '@resource/resource'

import { prefixAPI } from '@resource/api'
import { friendlyUrlGenerator } from '@utils/friendly'
import { cabinetOrder } from '@api/order'

const MODEL_PREFIX = 'catalog'
const MODEL_PREFIX_ORDER = 'order'

const ORDER_INFO = prefixAPI('/create/', MODEL_PREFIX_ORDER)
const ORDER_CREATE = prefixAPI('/create/', MODEL_PREFIX_ORDER)
const ORDER_DETAIL = prefixAPI('/order-history/{id}/', MODEL_PREFIX)
const ORDER_CHANGE_COMMENT = prefixAPI('/order-history/{id}/change_comment/', MODEL_PREFIX)
const ORDER_LIST = prefixAPI('/order-history', MODEL_PREFIX)
const ORDER_HISTORY = prefixAPI('/order-history/list_history', MODEL_PREFIX)
const ORDER_HISTORY_DETAIL = prefixAPI('/order-history/{id}/history_detail/', MODEL_PREFIX)
const ORDER_REPEAT = prefixAPI('/repeat-request/', MODEL_PREFIX_ORDER)

export const orderOptionsResource = createResource(ORDER_INFO, optionsResource)
export const orderCreateResource = createResource(ORDER_CREATE, postResource)
export const orderDetailResource = createResource(ORDER_DETAIL)
export const orderChangeCommentResource = createResource(ORDER_CHANGE_COMMENT, putResource)
export const orderRepeatResource = createResource(ORDER_REPEAT, postResource)
export const orderHistoryDetailResource = createResource(ORDER_HISTORY_DETAIL)

export const orderListResource = friendlyUrlGenerator(
  [ORDER_LIST, ''], baseResource, cabinetOrder
)
export const orderHistoryListResource = friendlyUrlGenerator(
  [ORDER_HISTORY, ''], baseResource, cabinetOrder
)
