import CheckoutView from './View'
import CheckoutUserInfo from './UserInfo'
import CheckoutPromocode from './Promocode'
import CheckoutPayment from './Payment'
import CheckoutOrder from './Order'
import CheckoutComment from './Comment'
import CheckoutDelivery from './Delivery'
import CheckoutDeliveryPickup from './DeliveryPickup'
import CheckoutDeliveryPostOffice from './DeliveryPostOffice'

export default function Components(Vue) {
  Vue.component('checkout-view', CheckoutView)
  Vue.component('checkout-user-info', CheckoutUserInfo)
  Vue.component('checkout-promocode', CheckoutPromocode)
  Vue.component('checkout-payment', CheckoutPayment)
  Vue.component('checkout-order', CheckoutOrder)
  Vue.component('checkout-comment', CheckoutComment)
  Vue.component('checkout-delivery', CheckoutDelivery)
  Vue.component('checkout-delivery-pickup', CheckoutDeliveryPickup)
  Vue.component('checkout-delivery-post-office', CheckoutDeliveryPostOffice)
}
